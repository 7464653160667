import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

const Wrapper = styled.div`
  position: relative;
  margin-right: 15px;
  width: 30px;
  height: 70px;
  cursor: pointer;
  display: none;

  ${MEDIA_QUERIES.TABLET} {
    display: inline-block;
    top: 7px;
  }
`;

const Line = styled.span`
  position: absolute;
  width: 30px;
  height: 2px;
  background: #4F4B5E;
  border-radius: 9px;
  display: ${props => props.isMenuOpen ? 'none' : 'block'};
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  &:nth-child(1) {
    top: ${props => props.isMenuOpen ? '26px' : '17px'};
    opacity: ${props => props.isMenuOpen ? '0' : '1'};
  }
  &:nth-child(2) {
    top: 26px;
    opacity: ${props => props.isMenuOpen ? '0' : '1'};
  }
  &:nth-child(3) {
    top: ${props => props.isMenuOpen ? '26px' : '35px'};
    opacity: ${props => props.isMenuOpen ? '0' : '1'};
  }
`;

class HamburgerButton extends Component {
  render() {
    const { onIconClick = ()=>{} } = this.props; //default empty function
    return (
      <Wrapper className="HamburgerButton" onClick={() => {onIconClick();}}>
        <Line isMenuOpen={this.props.isMenuOpen} />
        <Line isMenuOpen={this.props.isMenuOpen} />
        <Line isMenuOpen={this.props.isMenuOpen} />
      </Wrapper>
    )
  }
}

const mapStateToProps = ({navigate:{isMenuOpen}}) =>
  ({isMenuOpen})


export default connect(mapStateToProps)(HamburgerButton);
