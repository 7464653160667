/*
  This file is created for different break points for styled-components
*/

const breakpoints = {
  LEAST: 0,
  TINY: 320,
  MOBILE: 360,
  TABLET: 768,
  LAPTOP: 1025,
  DESKTOP: 1440,
  HD: 1920,
  _2K: 3001,
  _4K: 3841,
}

const breakpoint_max = (max) => {
  return `@media screen and (max-width: ${max}px)`;
}

const breakpoint_min = (min) => {
  return `@media screen and (max-width: ${min}px)`;
}

export default {
  BREAKPOINTS: breakpoints,
  _4K_ONLY: `@media screen and (min-width: 2000px) and (max-width: ${breakpoints._4K - 1}px)`,
  _2K_ONLY: `@media screen and (min-width: 2000px) and (max-width: ${breakpoints._2K - 1}px)`,
  HD_ONLY: `@media screen and (min-width: ${breakpoints.HD}px)`,
  DESKTOP_ONLY: `@media screen and (min-width: ${breakpoints.DESKTOP}px) and (max-width: ${breakpoints.HD - 1}px)`,
  LAPTOP_ONLY: `@media screen and (min-width: ${breakpoints.LAPTOP}px) and (max-width: ${breakpoints.DESKTOP - 1}px)`,
  TABLET_ONLY: `@media screen and (min-width: ${breakpoints.TABLET}px) and (max-width: ${breakpoints.LAPTOP - 1}px)`,
  MOBILE_ONLY: `@media screen and (min-width: ${breakpoints.LEAST}px) and (max-width: ${breakpoints.TABLET - 1}px)`,
  TINY_ONLY: `@media screen and (min-width: 0) and (max-width: ${breakpoints.TINY - 1}px)`,

  _4K: `@media screen and (max-width: ${breakpoints._4K - 1}px)`,
  _2K: `@media screen and (max-width: ${breakpoints._2K - 1}px)`,
  DESKTOP: `@media screen and (max-width: ${breakpoints.HD - 1}px)`,
  LAPTOP: `@media screen and (max-width: ${breakpoints.DESKTOP - 1}px)`,
  TABLET: `@media screen and (max-width: ${breakpoints.LAPTOP - 1}px)`,
  MOBILE: `@media screen and (max-width: ${breakpoints.TABLET - 1}px)`,
  TINY: `@media screen and (max-width: ${breakpoints.MOBILE - 1}px)`,

  TABLET_MIN: `@media screen and (min-width: ${breakpoints.TABLET}px)`,
  LAPTOP_MIN: `@media screen and (min-width: ${breakpoints.LAPTOP}px)`,
  DESKTOP_MIN: `@media screen and (min-width: ${breakpoints.DESKTOP}px)`,
  HD_MIN: `@media screen and (min-width: ${breakpoints.HD}px)`,
  _4K_MIN: `@media screen and (min-width: 2000px)`,

  BREAKPOINT_MAX: breakpoint_max,
  BREAKPOINT_MIN: breakpoint_min,

  TOUCH: `@media (hover: none)`,
}
