import React from 'react';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

import { BoxMiniSocial } from './components';
import sectionSocialJson from './sectionSocial.json';

const ExternalLink = props => <a href={props.to} target={props.target} rel="noopener noreferrer" >{props.children}</a>;


const COLOR_TEXT = `#000`;
const COLOR_BACKGROUND = `#fff`;
const COLOR_LIGHT = `#3E3E3E`;

const Wrapper = styled.div`
  font-size: 13px;
  font-family: "Gellix", sans-serif;
  color: ${COLOR_TEXT};
  letter-spacing: 0.56px;
  background: ${COLOR_BACKGROUND};
  padding: 54px 0;
  text-align: left;
  z-index: 3;
  ul {
    list-style: none;
  }
  a {
    cursor: pointer;
  }
  ${MEDIA_QUERIES.MOBILE} {
    padding: 32px 0 54px 0;
  }

  
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 61px;
  ${MEDIA_QUERIES.LAPTOP} {
    padding: 0 46px;
  }
  ${MEDIA_QUERIES.MOBILE} {
    padding: 0 30px;
  }
`;

const Section = styled.div`
  &:first-child{
    position: relative;
    flex: 0 0 248px;
    ${MEDIA_QUERIES.LAPTOP} {
      flex: 0 0 100%;
    }
    &:after{
      content: "";
      display: block;
      ${MEDIA_QUERIES.LAPTOP} {
        display: none;
      }
      position: absolute;
      top: 92px;
      right: 0;
      height: 180px;
      border-right: 1px solid rgba(255, 255, 255, 0.33);
    }
  }
  &:last-child{
    position: relative;
    flex: 1 0 0;
    padding-left: 75px;
    ${MEDIA_QUERIES.LAPTOP} {
      border-top: 1px solid rgba(255, 255, 255, 0.33);
      margin-top: 41px;
      padding-left: 0px;
      padding-top: 40px;
    }
    ${MEDIA_QUERIES.DESKTOP_MIN} {
      margin-top: 41px;
      padding-left: 0px;
      padding-top: 40px;
    }
  }
  :before {
    content: '';
    display: flex;
    //border-top: 1px solid #A2A2A2;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

const FooterWrapper = styled(Wrapper)`
  border-top: 1px solid #e6e6e6;
`;

const ContentRow = styled(Row)`
  //height: 170px;
  margin-bottom: 30px;
  ${MEDIA_QUERIES.LAPTOP} {
    height: 279px;
    margin-bottom: 30px;
    
  }
  ${MEDIA_QUERIES.MOBILE} {
    display: block;
    height: auto;
  }
`;

const Col = styled.div`
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  li{
    font-size: 13px;
    font-weight: 700;
    line-height: 28px;
  }
  ${MEDIA_QUERIES.MOBILE} {
    flex-basis: 50%;
    margin-top: 20px;
  }
`;

const LinksCol = styled(Col)`
  a {
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SmallTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 15px;
  color: ${COLOR_LIGHT};
`

const LogoCol = styled(Col)`
  display: flex;
  align-items: center;
  height: 31px;
  margin-bottom: 20px;
  ${MEDIA_QUERIES.MOBILE} {
    margin-bottom: 32px;
  }
`;

const SocialIconsCol = styled(Col)`
  flex-basis: 100%;
  ${MEDIA_QUERIES.LAPTOP} {
    flex-basis: 0;
  }
  ${MEDIA_QUERIES.MOBILE} {
    flex-basis: 100%;
  }
  ${SmallTitle} {
    display: none;
    ${MEDIA_QUERIES.LAPTOP} {
      display: block;
    }
  }
`;

const CopyRightCol = styled(Col)`
  text-align: right;
  font-size: 16px;
  padding-top: 10px;
  color: ${COLOR_LIGHT};

  ${MEDIA_QUERIES.MOBILE} {
    padding-top: 0;
    flex-basis: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 15px;
    margin-top: 15px;
  }

  ${MEDIA_QUERIES.TINY} {
    flex-basis: 100%;
    font-size: 10px;
    line-height: 12px;
    margin-top: 15px;
  }
`;

const SocialIconSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
  ${MEDIA_QUERIES.LAPTOP} {
    //width: 110px;
    padding-top: 8px;
  }
`

const SubscribeButton = styled.a`
  line-height: 32px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 24px;
  transition: color .2s ease;
  text-decoration: none;
  margin-right: 60px;
  
  &:hover {
    color: #0F4FE3;
    text-decoration: none;
    text-underline: none;
  }
  
  i {
    position: relative;
    top: -3px;
    font-size: 11px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    color: #000;
    transition: color .2s ease;
  }

  .b1i-circle:after:hover {
    background: blue;
  }
`;


const NewFooter = ({
  renderDataList = sectionSocialJson,
}) => (
  <FooterWrapper className="Footer">
    <Container>
      <Section>
        <Row>
          <LogoCol>
            <a href="https://eos.io" target="_blank" rel="noopener noreferrer">
              {/*<img src={eosio_logo} alt="eos.io"/>*/}
              <svg height="31" viewBox="0 0 196 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M82.0794 34.6909H57.9609C58.3232 41.0065 62.6524 45.1576 68.5877 45.1576C72.7478 45.1576 76.3102 43.4036 77.9736 40.4812L81.4213 42.6428C79.2235 46.5675 74.3509 49.0189 68.4699 49.0189C60.3188 49.0189 53.7374 42.6428 53.7374 34.0478C53.7237 32.1198 54.0942 30.2083 54.8274 28.425C55.5605 26.6418 56.6416 25.0224 58.0075 23.6616C59.3734 22.3007 60.9968 21.2256 62.7827 20.4991C64.5687 19.7726 66.4816 19.4092 68.4096 19.4301C76.1924 19.4301 82.1941 25.3351 82.1941 32.9369C82.1941 33.4622 82.1337 34.1082 82.0734 34.6909H82.0794ZM77.7381 31.37C77.3819 26.5186 73.3999 23.0105 68.2314 23.0105C63.1234 23.0105 59.2017 26.4008 58.1904 31.37H77.7381Z" fill="#0F4FE3"/>
                <path d="M117.311 34.2228C117.311 42.5853 110.717 49.0157 102.34 49.0157C93.962 49.0157 87.429 42.5853 87.429 34.2228C87.429 25.8603 93.962 19.4299 102.34 19.4299C110.717 19.4299 117.311 25.8633 117.311 34.2228ZM91.7068 34.2228C91.7068 40.2607 96.3409 44.9824 102.34 44.9824C103.752 44.993 105.153 44.7214 106.46 44.1836C107.766 43.6458 108.952 42.8526 109.948 41.8505C110.944 40.8483 111.73 39.6575 112.259 38.3477C112.789 37.0379 113.052 35.6355 113.033 34.2228C113.033 28.1426 108.341 23.4663 102.34 23.4663C96.3379 23.4663 91.7068 28.1426 91.7068 34.2228Z" fill="#0F4FE3"/>
                <path d="M122.896 44.925L124.858 41.7098C127.113 43.9318 131.095 45.4503 134.479 45.4503C138.461 45.4503 141.015 43.4608 141.015 40.8313C141.015 33.4651 123.729 38.3165 123.729 27.7321C123.729 22.7629 128.007 19.4299 134.295 19.4299C138.22 19.4299 142.196 20.716 144.454 23.1131L142.434 26.3282C140.472 24.215 136.728 23.0074 133.936 23.0074C130.374 23.0074 128.055 24.7614 128.055 27.4543C128.055 34.1775 145.405 29.7336 145.405 40.7377C145.405 45.5318 141.006 49.0429 134.537 49.0429C130.201 49.0429 125.628 47.4036 122.893 44.9492" fill="#0F4FE3"/>
                <path d="M159.277 10.66C159.255 11.4819 158.916 12.2635 158.331 12.8409C157.746 13.4184 156.96 13.7468 156.138 13.7575C155.726 13.7704 155.317 13.7005 154.933 13.5521C154.55 13.4037 154.2 13.1797 153.905 12.8935C153.609 12.6072 153.374 12.2646 153.214 11.8858C153.054 11.5071 152.971 11.1 152.971 10.6887C152.971 10.2774 153.054 9.87029 153.214 9.49154C153.374 9.1128 153.609 8.77014 153.905 8.4839C154.2 8.19766 154.55 7.97369 154.933 7.82527C155.317 7.67686 155.726 7.60702 156.138 7.61992C157.801 7.61992 159.286 9.02373 159.286 10.66H159.277ZM158.275 20.0188V48.4332H153.931V24.3057H150.842V20.0158L158.275 20.0188Z" fill="#0F4FE3"/>
                <path d="M195.139 34.2228C195.139 42.5853 188.543 49.0157 180.168 49.0157C171.794 49.0157 165.258 42.5853 165.258 34.2228C165.258 25.8603 171.791 19.4299 180.168 19.4299C188.546 19.4299 195.139 25.8633 195.139 34.2228ZM169.533 34.2228C169.533 40.2607 174.167 44.9824 180.168 44.9824C181.581 44.9922 182.981 44.72 184.286 44.1818C185.592 43.6437 186.777 42.8503 187.773 41.8483C188.768 40.8462 189.553 39.6557 190.083 38.3463C190.612 37.0369 190.875 35.635 190.855 34.2228C190.855 28.1426 186.164 23.4663 180.162 23.4663C174.161 23.4663 169.526 28.1426 169.526 34.2228" fill="#0F4FE3"/>
                <path d="M33.2448 24.9426L37.894 46.8331L22.7388 55.7903L33.2448 24.9426ZM9.41613 19.9644L20.6165 4.93902L31.8108 19.9644L20.6165 52.8438L9.41613 19.9644ZM3.33595 46.8331L7.98514 24.9396L18.4911 55.7903L3.33595 46.8331ZM35.1105 19.4602L20.6195 0L6.12849 19.4602L0 48.2701L20.6165 60.4546L41.2299 48.2701L35.1105 19.4602Z" fill="#0F4FE3"/>
              </svg>

            </a>
          </LogoCol>
        </Row>
        <ContentRow>
          <Col>
            <ul>
              <li><SubscribeButton href="https://eos.us15.list-manage.com/subscribe/post?u=f113d9b507d4714a62f3824a9&amp;id=d1b9a7357c&amp;=EMAIL=DUMMY%40gmail.com" target={"_blank"}>Sign up to our newsletter <i className={"b1i-arrow-right b1i-circle"}></i>
              </SubscribeButton></li>
            </ul>
          </Col>
          <LinksCol>
            <ul>
              {/*TODO: Check links*/}
              <li>
                <ExternalLink to="https://eos.io/eosio-for-business/" target="_blank">For Business</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://developers.eos.io/" target="_blank">For Developers</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/webinars/" target="_blank">Webinars</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/news/" target="_blank">News & Events</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/" target="_blank">About EOSIO</ExternalLink>
              </li>

              <li>
                <ExternalLink to="https://developers.eos.io/" target="_blank">Developer Portal</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://battles.eos.io/" target="_blank">Elemental Battles</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://testnet.eos.io/" target="_blank">Testnet</ExternalLink>
              </li>

            </ul>
          </LinksCol>
          <LinksCol>
            <ul>
              <li>
                <ExternalLink to="https://block.one" target="_blank">Block.one</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://developers.eos.io" target="_blank">Developer Portal</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://github.com/EOSIO" target="_blank">Github</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eosio.stackexchange.com" target="_blank">StackExchange</ExternalLink>
              </li>
            </ul>
          </LinksCol>
          <LinksCol>
            <ul>
              <li>
                <ExternalLink to="https://eos.io/contact/" target="_blank">Contact</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/faq/" target="_blank">FAQs</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/legal/privacy-policy/" target="_blank">Privacy Policy</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/legal/terms-of-use/" target="_blank">Terms of Use</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/legal/important-notice/" target="_blank">Important Notice</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://eos.io/feedback/" target="_blank">Feedback</ExternalLink>
              </li>
              <li>
                <ExternalLink to="https://developers.eos.io/sitemap.xml" target="_blank">Sitemap</ExternalLink>
              </li>

            </ul>
          </LinksCol>
        </ContentRow>
        <ContentRow>
          <Col>
            <ul>
              <li>
                <SocialIconSet>
                  {renderDataList.map((renderData, index) => <BoxMiniSocial key={index} {...renderData}/>)}
                </SocialIconSet>
              </li>
            </ul>
          </Col>
          <CopyRightCol>
            <span>© 2021 Block.one. All rights reserved.</span>
          </CopyRightCol>

        </ContentRow>
      </Section>
    </Container>
  </FooterWrapper>
);

export default NewFooter;
