import React from 'react';

const EosioLogoSmallSVG = ({ className }) =>

  <svg {...{ className }} width="42" height="60" viewBox="0 0 42 60" fill="none"
       xmlns="http://www.w3.org/2000/svg">
      <path d="M33.2448 24.9426L37.894 46.8331L22.7388 55.7903L33.2448 24.9426ZM9.41613 19.9644L20.6165 4.93902L31.8108 19.9644L20.6165 52.8438L9.41613 19.9644ZM3.33595 46.8331L7.98514 24.9396L18.4911 55.7903L3.33595 46.8331ZM35.1105 19.4602L20.6195 0L6.12849 19.4602L0 48.2701L20.6165 60.4546L41.2299 48.2701L35.1105 19.4602Z" fill="#0F4FE3"/>
  </svg>


export default EosioLogoSmallSVG;
